import { Component, Vue } from 'vue-property-decorator';
import template from './PrimeEco.Template.vue';

@Component({
    ...template,
    name: 'PrimeEco',
})
export default class PrimeEco extends Vue {

    public nbOperationsStandards: number = null;

    /**
     * Mounted.
     */
    public mounted() {
        this.getNbOperationsStandards();
    }

    /**
     * Récupération du nombre d'opérations standards.
     */
    private getNbOperationsStandards() {
        this.$http.get(`/templateOperations/nbOperationsStandards`).then((result: any) => {
            if (result && result.data) {
                this.nbOperationsStandards = result.data.data;
            }
        });
    }
}
